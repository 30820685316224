.nav {
    display: flex;
    width: 50%;
    justify-content: flex-end;
}

.nav__text {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-size: 18px;
    line-height: 24px;
    color: #fff;
    align-self: center;
    font-weight: 500;
    text-decoration: none;
    padding-bottom: 23px;
    padding-top: 30px;
}

.nav__text_black {
    color: #1A1B22;
}

.nav__text_saved{
    margin-left: 42px;
}

.nav__btn {
    border-radius: 100px;
    border: 1px solid #fff;
    background-color: transparent;
    min-width: 176px;
    max-height: 48px;
    padding: 12px;
    margin: 19px 30px 19px 42px;
    
}

.nav__btn_black {
    border: 1px solid #1A1B22;
}

.nav__btn-username {
    text-overflow: ellipsis;
    margin-right: 20px;
}

.nav__text:hover {
    opacity: .5;
    border-bottom: 3px solid #fff;
}

.nav__text_active {
    border-bottom: 3px solid #fff;
}

.nav__text_active-black {
    border-bottom: 3px solid #1A1B22;
}

.nav__close-btn {
    background-image: url('../../images/close.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    border: none;
    margin-right: 16px;
    width: 24px;
    height: 24px;
    z-index: 2;
}

@media screen and (max-width: 1024px) {
    .nav__btn{
        margin-right: 16px;
    }
}
@media screen and (max-width: 800px) {
    
    .nav__text {
        font-size: 16px;
    }
    .nav__btn {
        padding: 8px 12px;
        min-width: 152px;
        max-height: 40px;
    }
}

@media screen and (max-width: 600px) {
    .nav{
        width: 100%;
    }
    .nav_mobile {
        height: auto;
        flex-direction: column;
        align-self: center;
    }
    .nav__text {
        font-size: 18px;
       
    }
    .nav__text_active{
        border-bottom: none;
    }
    .nav__text_active-black{
        border-bottom: none;
    }
    .nav__text_home {
        align-self: start;
        margin: 0px 16px;
    }
    .nav__text_saved {
        align-self: start;
        margin-left: 16px;
        margin-bottom: 28px;
    }
    .nav__btn {
        width: 288px;
        align-self: start;
        margin: 0px 16px;
        
    }
    .nav__hamburger {
        z-index: 1;
    }
}