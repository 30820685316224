.main {
    max-width: 1440px;
    margin: 0 auto;
}

.main__container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.main__header {
    font-family: 'Roboto Slab', 'Roboto', Arial, Helvetica, sans-serif;
    font-weight: 400;
    font-size: 60px;
    line-height: 1.07;
    color: #fff;
    max-width: 608px;
    margin-top: 160px;
    margin-bottom: 32px;
    align-self: center;
}

.main__text {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-size: 18px;
    font-weight: normal;
    line-height: 1.33;
    color: #fff;
    max-width: 608px;
    margin: 0 auto;
}

@media screen and (max-width: 800px) {
    .main__header {
        font-size: 36px;
        line-height: 1.11;
        max-width: 452px;
        margin-bottom: 16px;
    }
    .main__text {
        max-width: 452px;
    }
}

@media screen and (max-width: 600px) {
    .main__header {
        margin-top: 100px;
    }
}

@media screen and (max-width: 500px) {
    .main__header {
        max-width: 320px;
        width: calc(100% - 2*16px);
    }

    .main__text{
        max-width: 320px;
        width: calc(100% - 2*16px);
    }
    .search__header {
        max-width: 285px;
        margin-top: 30px;
    }
    .search__text {
        max-width: 288px;
    }
}