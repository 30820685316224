.nav__mobile{
    position: absolute;
    background-color: rgba(0,0,0, .5);
    z-index: 1;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
}

.nav__mobile-hidden{
    display: none;
}

.nav__mobile-container{
    width: 100%;
    height: auto;
    background-color: #1a1b22;
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
    
}

.nav__mobile-header{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid #D1D2D6;
}

