.search {
    background: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.search__form {
    display: flex;
    min-width: 608px;
    min-height: 64px;
    border-radius: 100px;
    position: relative;
    padding-top: 88px;
    padding-bottom: 80px;
}

.search__input {
    border: 1px solid #D1D2D6;
    border-radius: 100px;
    padding: 20px;
    border: none;
    flex-grow: 2;
    background: #fff;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-size: 16px;
    line-height: 1.37;
    color: #b6bcbf;
}

.search__btn {
    border-radius: 100px;
    background-color: #2f71e5;
    border: none;
    box-shadow: 0px 5px 15px rgba(14, 26, 57, 0.2);
    min-width: 168px;
    color: #fff;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    padding: 20px;
    position: absolute;
    right: 0;
}

.search__btn:hover{
    background-color: #347EFF;
}

.search__btn:active{
    background-color: #2A65CC;
}

.search__error{
    color: red;
    padding-bottom: 25px;
}
@media screen and (max-width: 800px) {
    
    .search__form {
        min-width: 452px;
        min-height: 56px;
        padding-top: 48px;
        padding-bottom: 48px;
    }
    .search__input {
        padding: 16px;
    }
    .search__btn {
        padding: 16px;
        min-width: 160px;
    }
}

@media screen and (max-width: 500px) {
    
    .search__form{
        min-width: 288px;
        flex-direction: column;
        padding-top: 122px;
        padding-bottom: 32px;
    }
    .search__input{        
        margin-bottom: 16px;
        
    }
    .search__btn{
        position: static;
        min-height: 56px;
    }
}