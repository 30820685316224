.footer {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 2*30px);
    margin: 0 auto;
}

.footer__copyright {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-size: 16px;
    line-height: 1.37;
    color: #B6BCBF;
}

.footer__links {
    width: 50%;
}

.footer__list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    list-style-type: none;
}

.footer__link {
    text-decoration: none;
    color: #1A1B22;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
}

@media screen and (max-width: 1024px) {
    .footer {
        width: calc(100% - 2*16px);
    }
}

@media screen and (max-width: 725px) {
    .footer {
        flex-direction: column-reverse;
        width: calc(100% - 2*16px);
    }
    .footer__links {
        width: 100%;
    }
    .footer__list {
        display: grid;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 200px 1fr 1fr;
        gap: 20px;
        padding-left: 0;
        margin: 0;
    }
    .footer__list-item {
        grid-row: 2/3;
        grid-column: 1/ span 3;
    }
}