@font-face{
    src:url('../fonts/inter-400-regular.woff') format('woff'),
    url('../fonts/inter-400-regular.woff2') format('woff2');
    font-family: 'Inter';
}

@font-face{
    src: url('../fonts/inter-500.woff') format('woff'),
    url('../fonts/inter-500.woff2') format('woff2');
    font-family: 'Inter';
    font-weight: 500;
}

@font-face{
    src: url('../fonts/roboto-400-regular.woff') format('woff'),
    url('../fonts/roboto-400-regular.woff2') format('woff2');
    font-family: 'Roboto';
    font-weight: 400;
}

@font-face{
    src: url('../fonts/roboto-500.woff') format('woff'),
    url('../fonts/roboto-500.woff2') format('woff2');
    font-family: 'Roboto';
    font-weight: 500;
}

@font-face{
    src: url('../fonts/roboto-700.woff') format('woff'),
    url('../fonts/roboto-700.woff2') format('woff2');
    font-family: 'Roboto';
    font-weight: 700;
}

@font-face{
    src: url('../fonts/roboto-900.woff') format('woff'),
    url('../fonts/roboto-900.woff2') format('woff2');
    font-family: 'Roboto';
    font-weight: 900;
}

@font-face{
    src: url('../fonts/roboto-slab-400-regular.woff') format('woff'),
    url('../fonts/roboto-slab-400-regular.woff2') format('woff2');
    font-family: 'Roboto Slab';
    font-weight: 400;
}

@font-face{
    src: url('../fonts/roboto-slab-700.woff') format('woff'),
    url('../fonts/roboto-slab-700.woff2') format('woff2');
    font-family: 'Roboto Slab';
    font-weight: 700;
}



