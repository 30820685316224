.nothing-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 80px;
    background: #F5F6F7;
}

.nothing-found__img {
    margin-bottom: 24px;
}

.nothing-found__title {
    font-family: 'Roboto Slab', 'Roboto', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-size: 26px;
    line-height: 1.15;
    color: #1A1B22;
    margin-top: 0;
    margin-bottom: 16px;
}

.nothing-found__message {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-size: 18px;
    line-height: 1.33;
    color: #B6BCBF;
    margin: 0 auto;
    padding-bottom: 80px;
}

@media screen and (max-width: 400px) {
    .nothing-found__message {
        max-width: 320px;
        width: calc(100% - 2*16px);
        text-align: center;
    }
}