.preloader {
	display: flex;
    flex-direction: column;
    align-items: center;
	padding-top: 80px;
	background: #F5F6F7;
}

.preloader__circle {
	display: block;
	width: 50px;
	height: 50px;
	border: 4px solid #444;
	border-bottom-color: #888;	
	border-radius: 50%;
	margin-bottom: 24px;
	animation: spin .75s infinite linear;
}

.preloader__message{
	font-family: 'Roboto', Arial, Helvetica, sans-serif;
	font-weight: normal;
	font-size: 18px;
	line-height: 1.33;
	color: #B6BCBF;
	margin: 0;
	padding-bottom: 80px;
}

@keyframes spin {
	100% {
		transform: rotate(360deg);
	}
}